.container {
    display: flex;
  }
  
  .content {
    width: 80%;
    margin-left: 20%; /* Matches sidebar width */
    padding: 2rem;
    background: #ffffff; /* White background for content */
    color: #111827; /* Dark text for contrast */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  