.sidebar {
    width: 20%;
    height: 100vh;
    background: transparent; /* Transparent background */
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  
  .nav ul {
    list-style: none;
    padding: 0;
  }
  
  .nav li {
    margin: 2rem 0;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1rem;
    position: relative;
  }
  
  .nav li a {
    text-decoration: none;
    letter-spacing: 0.2em;
    color: rgba(0, 0, 0, 0.6);
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
 
  .nav li:hover a {
    color: rgba(0, 0, 0, 0.9); /* Dark hover color */
  }
  
  .nav li.active a {
    color: orangered; /* Active item text color */
  }
  
  .nav li::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.2em;
    bottom: -0.5rem;
    left: 0;
    background-image: linear-gradient(to right, #FF6E40, #FF3D00);
    opacity: 0;
    transform: scaleX(0);
    transform-origin: right;
    transition: opacity 0.3s, transform 0.3s;
  }
  
  .nav li:hover::after {
    opacity: 1;
    transform: scaleX(1);
    transform-origin: left;
  }
  