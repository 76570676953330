@import url('https://fonts.googleapis.com/css?family=Roboto:700');

@keyframes showTopText {
  0% { transform: translate3d(0, 100%, 0); }
  40%, 60% { transform: translate3d(0, 50%, 0); }
  100% { transform: translate3d(0, 0, 0); }
}

@keyframes showBottomText {
  0% { transform: translate3d(0, -100%, 0); }
  100% { transform: translate3d(0, 0, 0); }
}

.animated-title {
  font-family: Roboto, Arial, sans-serif;
  height: 90vmin;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90vmin;
}

.animated-title > div {
  height: 50%;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.animated-title > div div {
  font-size: 10vmin;
  padding: 2vmin 0;
  position: absolute;
}

.animated-title > div div span {
  display: block;
}

.animated-title > div.text-top {
  border-bottom: 1vmin solid #6d3f1a; /* Light mode color */
  top: 0;
}

/* Dark mode adjustment for the border */
.dark .animated-title > div.text-top {
  border-bottom-color: #a8a8a8; /* Same as the text color in dark mode */
}

.animated-title > div.text-top div {
  animation: showTopText 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  bottom: 0;
  transform: translate(0, 100%);
}

/* Apply the color and gradient in light and dark mode */
.animated-title > div.text-top div span:first-child {
  color: #6d3f1a; /* Light mode color */
}

.dark .animated-title > div.text-top div span:first-child {
  color: #a8a8a8; /* Gray for dark mode */
}

/* Apply the gradient to the second span (team today) */
.animated-title > div.text-top div span:nth-child(2) {
  background: linear-gradient(90deg, #FFA500, #FF4500);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.animated-title > div.text-bottom {
  bottom: 0;
}

/* Apply the gradient to the bottom text (start now!) */
.animated-title > div.text-bottom div {
  background: linear-gradient(90deg, #FFA500, #FF4500);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.animated-title > div.text-bottom div {
  animation: showBottomText 0.5s;
  animation-delay: 1.75s;
  animation-fill-mode: forwards;
  top: 0;
  transform: translate(0, -100%);
}
