:root {
  --color: #FF6600;
  --transition-time: 0.5s;
}

.cardsWrapper {
  display: grid; /* Use grid layout */
  grid-template-columns: repeat(3, 1fr); /* Always display 3 cards per row */
  gap: 2.5rem; /* Adjust gap for spacing between cards */
  padding: 2rem;
  box-sizing: border-box; /* Include padding in width calculations */
}

.cardGridSpace {
  position: relative;
}

.num {
  font-size: 2em; /* Adjust for better proportion */
  margin-bottom: 1rem;
  margin-left: 1rem;
  color: var(--color);
  font-weight: bold;
}

.card {
  font-family: 'Heebo', sans-serif;
  --bg-filter-opacity: 0.5;
  background-image: linear-gradient(
      rgba(0, 0, 0, var(--bg-filter-opacity)),
      rgba(0, 0, 0, var(--bg-filter-opacity))
    ),
    var(--bg-img);
  height: 20rem; /* Maintain card height */
  width: 100%; /* Full width within the grid item */
  font-size: 1.2rem;
  color: white;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  align-items: flex-end;
  background-size: cover;
  background-position: center;
  box-shadow: 0 0 2rem -0.5rem black; /* Subtle shadow */
  transition: transform var(--transition-time), box-shadow var(--transition-time);
  position: relative;
  overflow: hidden;
  border: 0.3rem solid #ccc;
  text-decoration: none;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 0 3rem -0.5rem burlywood;
}

.card h1 {
  margin: 0;
  font-size: 1.25rem; /* Slightly smaller for compactness */
  line-height: 1.2rem;
}

.card p {
  font-size: 0.875rem;
  font-family: 'Open Sans', sans-serif;
  margin-top: 0.5rem;
  line-height: 1.5rem;
}

.card .tags {
  display: flex;
}

.card .tag {
  font-size: 0.75rem;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 0.3rem;
  padding: 0 0.5rem;
  margin-right: 0.5rem;
  line-height: 1.5rem;
  transition: background-color var(--transition-time), color var(--transition-time);
}

.card:hover .tag {
  background: var(--color);
  color: white;
}

.card .date {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.75rem;
  padding: 1rem;
  line-height: 1rem;
  opacity: 0.8;
}
