/* Title color for the calendar */
.fc-toolbar-title {
    color: #f15a24; /* Primary orange color for the title */
}

/* General button styles */
.fc .fc-button {
    background-color: #ff6600 !important; /* Primary orange color */
    color: #ffffff !important; /* White text color */
    border: none !important;
}


/* Button hover effect with a lighter orange */
.fc .fc-button:hover {
    background-color: #f15a24 !important; /* Lighter orange for hover */
}

/* Navigation buttons (Previous and Next) */
.fc-prev-button, .fc-next-button {
    background-color: #f15a24 !important; /* Primary orange for navigation */
    color: #ffffff !important;
    border: none !important;
    background-image: none !important;
}
/* Styling for the border on focus */
.fc .fc-prev-button:focus,
.fc .fc-next-button:focus {
    outline: none; /* Remove default outline */
    border: 2px solid #ff6600 !important; /* Custom border color (orange) */
    box-shadow: 0 0 5px rgba(255, 102, 0, 0.5); /* Optional shadow for extra styling */
}

/* Styling for the border on active (clicked) state */
.fc .fc-prev-button:active,
.fc .fc-next-button:active {
    outline: none; /* Remove default outline */
    border: 2px solid #f15a24 !important; /* Lighter orange border for active state */
    box-shadow: 0 0 5px rgba(241, 90, 36, 0.5); /* Optional shadow for extra styling */
}


/* Style for the "Today" button */
.fc-today-button.fc-button-primary {
    background-color: #ff6600 !important; /* Primary orange color */
    color: #ffffff !important;            /* White text color */
    border: none !important;
}

/* Hover effect for "Today" button */
.fc-today-button.fc-button-primary:hover {
    background-color: #ff7316 !important; /* Slightly lighter orange on hover */
    color: #ffffff !important;
}

/* Disabled state (optional if you want a different color when disabled) */
.fc-today-button.fc-button-primary:disabled {
    background-color: #ff6600 !important;    /* Gray color when disabled */
    color: #ffffff !important;
}


/* Active button style */
.fc .fc-button-active {
    background-color: #ff7f50 !important; /* Slightly lighter orange for active state */
    color: #ffffff !important;
}

.fc-event {
  white-space: nowrap;         /* keep them on one line (or use 'normal' + line-clamp) */
  overflow: hidden;           /* hide overflow */
  text-overflow: ellipsis;    /* show ellipsis if text is clipped */
  max-width: 100%;            /* ensure ellipsis can kick in */
  height: 1.6em; 
}


.fc-event-title {
  font-family: 'Roboto', sans-serif;
  font-size: 0.95rem;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  letter-spacing: 0.4px;
  text-transform: capitalize;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  display: block;
  white-space: normal !important;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  padding: 3px 6px;
}
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .fc-event-title {
        font-size: 0.85rem !important;
        padding: 2px 4px;
    }
}
  
  @media (min-width: 769px) and (max-width: 1024px) {
    .fc-event-title {
      font-size: 0.9rem;
    }
  }
  
  
@keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .fade-in {
    animation: fadeIn 0.3s ease-out forwards;
  }